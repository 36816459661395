@font-face {
    font-family: PoppinsRegular;
    src: url("../fonts/Poppins/Poppins-Regular.ttf");
}
@font-face {
    font-family: PoppinsItalic;
    src: url("../fonts/Poppins/Poppins-Italic.ttf");
}
@font-face {
    font-family: PoppinsSemiBold;
    src: url("../fonts/Poppins/Poppins-SemiBold.ttf");
}
@font-face {
    font-family: PoppinsBold;
    src: url("../fonts/Poppins/Poppins-Bold.ttf");
}
@font-face {
    font-family: PoppinsMedium;
    src: url("../fonts/Poppins/Poppins-Medium.ttf");
}

@font-face {
    font-family: MontserratBold;
    src: url("../fonts/Montserrat/montserrat_bold.ttf");
}

body {
    overflow-x: hidden;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

a { text-decoration: none; }

/*
::-webkit-scrollbar {
    width: 0px;
}
*/

.horizontalStack {
    display: flex;
    flex-direction: row;
}
.horizontalStackCenter {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.horizontalStackBottom {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}
.verticalStack {
    display: flex;
    flex-direction: column;
}
.verticalStackCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.centerAll {
    align-items: center;
    justify-content: center;
}
.flex {
    flex: 1;
}
.clickable:hover {
    cursor: pointer;
}

/* FONTS */

.fontPoppinsMedium9 {
    font-family: PoppinsMedium;
    font-size: 9px;
}

.fontPoppinsRegular13 {
    font-family: PoppinsRegular;
    font-size: 13px;
    color: var(--black);
}

.fontPoppinsRegular15 {
    font-family: PoppinsRegular;
    font-size: 15px;
}

.fontPoppinsRegular27 {
    font-family: PoppinsRegular;
    font-size: 27px;
    color: var(--black);
}

.fontPoppinsRegular27Orange {
    font-family: PoppinsRegular;
    font-size: 27px;
    color: var(--jv-orange);
}

.fontPoppinsItalic15 {
    font-family: PoppinsItalic;
    font-size: 15px;
}

.fontPoppinsItalic15Orange {
    font-family: PoppinsItalic;
    font-size: 15px;
    color: var(--jv-orange);
}

.fontPoppinsItalic15DarkGray {
    font-family: PoppinsItalic;
    font-size: 15px;
    color: var(--dark-gray);
}

.fontPoppinsItalic13 {
    font-family: PoppinsItalic;
    font-size: 13px;
}

.fontPoppinsItalic13Blue {
    font-family: PoppinsItalic;
    font-size: 13px;
    color: var(--blue-button-link);
}

.fontPoppinsItalic13Orange {
    font-family: PoppinsItalic;
    font-size: 13px;
    color: var(--jv-orange);
}

.fontPoppinsItalic13Gray {
    font-family: PoppinsItalic;
    font-size: 13px;
    color: var(--dark-gray);
}

.fontPoppinsItalic13White {
    font-family: PoppinsItalic;
    font-size: 13px;
    color: var(--white);
}

.fontPoppinsRegular13Gray {
    font-family: PoppinsRegular;
    font-size: 13px;
    color: var(--dark-gray);
}

.fontPoppinsRegular13Orange {
    font-family: PoppinsRegular;
    font-size: 13px;
    color: var(--jv-orange);
}

.fontPoppinsRegular13DarkGray {
    font-family: PoppinsRegular;
    font-size: 13px;
    color: var(--darkest-gray);
}

.fontPoppinsRegular13AlmostBlack {
    font-family: PoppinsRegular;
    font-size: 13px;
    color: var(--full-black);
}

.fontPoppinsRegular13PurpleGray {
    font-family: PoppinsRegular;
    font-size: 13px;
    color: var(--purple-gray);
}

.fontPoppinsRegular13LightPurpleGray {
    font-family: PoppinsRegular;
    font-size: 13px;
    color: var(--light-purple-gray);
}

.fontPoppinsRegular13White {
    font-family: PoppinsRegular;
    font-size: 13px;
    color: var(--white);
}

.fontPoppinsRegular13Blue {
    font-family: PoppinsRegular;
    font-size: 13px;
    color: var(--blue-button-link);
}

.fontPoppinsSemiBold11 {
    font-family: PoppinsSemiBold;
    font-size: 11px;
}

.fontPoppinsSemiBold13 {
    font-family: PoppinsSemiBold;
    font-size: 13px;
}

.fontPoppinsBold13 {
    font-family: PoppinsBold;
    font-size: 13px;
}

.fontPoppinsSemiBold13Gray {
    font-family: PoppinsSemiBold;
    font-size: 13px;
    color: var(--dark-gray);
}

.fontPoppinsSemiBold13DarkGray {
    font-family: PoppinsSemiBold;
    font-size: 13px;
    color: var(--darkest-gray);
}

.fontPoppinsSemiBold13LightGray {
    font-family: PoppinsSemiBold;
    font-size: 13px;
    color: var(--light-purple-gray);
}

.fontPoppinsSemiBold13Blue {
    font-family: PoppinsSemiBold;
    font-size: 13px;
    color: var(--blue-button-link);
}

.fontPoppinsSemiBold16 {
    font-family: PoppinsSemiBold;
    font-size: 16px;
    color: var(--light-purple-gray);
}

.fontPoppinsSemiBold15 {
    font-family: PoppinsSemiBold;
    font-size: 15px;
}
.fontPoppinsSemiBold15Gray {
    font-family: PoppinsSemiBold;
    font-size: 15px;
    color: var(--dark-gray);
}
.fontPoppinsSemiBold15Blue {
    font-family: PoppinsSemiBold;
    font-size: 15px;
    color: var(--blue-button-link);
}
.fontPoppinsSemiBold15Orange {
    font-family: PoppinsSemiBold;
    font-size: 15px;
    color: var(--jv-orange);
}

.fontPoppinsBold15 {
    font-family: PoppinsBold;
    font-size: 15px;
}

.fontPoppinsItalic13 {
    font-family: PoppinsItalic;
    font-size: 13px;
}

.fontPoppinsItalic13Gray {
    font-family: PoppinsItalic;
    font-size: 13px;
    color: var(--dark-gray);
}

.fontPoppinsItalic13DarkGray {
    font-family: PoppinsItalic;
    font-size: 13px;
    color: var(--darkest-gray);
}

.fontPoppinsItalic13PurpleGray {
    font-family: PoppinsItalic;
    font-size: 13px;
    color: var(--purple-gray);
}

.fontPoppinsMedium13White {
    font-family: PoppinsMedium;
    font-size: 13px;
    color: var(--white);
}

.fontPoppinsMedium13Blue {
    font-family: PoppinsMedium;
    font-size: 13px;
    color: var(--blue-button-link);
}

.fontPoppinsMedium13Gray {
    font-family: PoppinsMedium;
    font-size: 13px;
    color: var(--dark-gray);
}

.fontPoppinsMedium14 {
    font-family: PoppinsMedium;
    font-size: 14px;
}

.fontPoppinsMedium14DarkGray {
    font-family: PoppinsMedium;
    font-size: 14px;
    color: var(--darkest-gray);
}

.fontPoppinsMedium14Blue {
    font-family: PoppinsMedium;
    font-size: 14px;
    color: var(--blue-button-link);
}

.fontPoppinsMedium15 {
    font-family: PoppinsMedium;
    font-size: 15px;
}

.fontPoppinsMedium15DarkGray {
    font-family: PoppinsMedium;
    font-size: 15px;
    color: var(--darkest-gray);
}

.fontMontserratBold18 {
    font-family: MontserratBold;
    font-size: 18px;
    color: var(--black);
}

.fontMontserratBold18Orange {
    font-family: MontserratBold;
    font-size: 18px;
    color: var(--jv-orange);
}

.fontPoppinsMedium20 {
    font-family: PoppinsMedium;
    font-size: 20px;
}

.fontPoppinsMedium20Gray {
    font-family: PoppinsMedium;
    font-size: 20px;
    color: var(--dark-gray);
}

.fontPoppinsMedium20White {
    font-family: PoppinsMedium;
    font-size: 20px;
    color: var(--white);
}

.fontPoppinsMedium20Orange {
    font-family: PoppinsMedium;
    font-size: 20px;
    color: var(--jv-orange);
}

.fontPoppinsBold25 {
    font-family: PoppinsBold;
    font-size: 25px;
}

.fontPoppinsBold25Orange {
    font-family: PoppinsBold;
    font-size: 25px;
    color: var(--jv-orange);
}

.fontPoppinsMedium40White {
    font-family: PoppinsRegular;
    font-size: 40px;
    color: var(--white);
}

.fontPoppinsMedium60White {
    font-family: PoppinsMedium;
    font-size: 60pt;
    color: var(--white);
}

.fontMontserratBold25DarkBlue {
    font-family: MontserratBold;
    font-size: 25px;
    color: var(--full-black);
}
.fontMontserratBold25Green {
    font-family: MontserratBold;
    font-size: 25px;
    color: var(--crm-green);
}
.fontMontserratBold25Orange {
    font-family: MontserratBold;
    font-size: 25px;
    color: var(--jv-orange);
}

/* END OF FONTS */

.colorToOrange {
    filter: invert(45%) sepia(23%) saturate(7498%) hue-rotate(2deg) brightness(104%) contrast(103%); /* change color to orange var(--jv-orange) */
}

.onHoverToOrange:hover {
    filter: invert(45%) sepia(23%) saturate(7498%) hue-rotate(2deg) brightness(104%) contrast(103%); /* change color to orange var(--jv-orange) */
}
/* on some places onHoverToOrange did not convert it to orange color and this onHoverAlsoToOrange did that */
.onHoverAlsoToOrange:hover {
    filter: invert(50%) sepia(62%) saturate(4846%) hue-rotate(1deg) brightness(105%) contrast(105%);
}
.onHoverToPink:hover {
    filter: invert(54%) sepia(37%) saturate(5575%) hue-rotate(297deg) brightness(99%) contrast(98%);
}
.onHoverTextToWhite:hover {
    color: var(--white);
}
.onHoverToBlack:hover {
    color: var(--black);
}
.onHoverToRed:hover {
    color: var(--delete-logout-red);
}
.onHoverToBlue:hover {
    color: var(--blue-button-link);
}

.responsiveThreeDotsContainer {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.responsiveThreeDots {
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
     display: table-cell;
 }

/* UNIVERSAL POPUP STYLES */

.universalPopupContainer {
    border: none;
    position: relative;
}

.universalPopupVisible {
    /*height: 100%;*/
    position: relative;
}

.universalPopupHidden {
    display: none;
    position: absolute;
    z-index: 1;
    height: 100%;
    margin-left: 30px;
}

.universalPopupContainer:hover .universalPopupHidden {
    display: block;
}

/* END OF UNIVERSAL POPUP STYLES */





/* POPUP BUTTON DESCRIPTION FROM TOP STYLES */

.popupButtonDescriptionFromTopContainer {
    border: none;
    position: relative;
}

.popupButtonDescriptionFromTopVisible {
    /*height: 100%;*/
    position: relative;
}

.popupButtonDescriptionFromTopHidden {
    display: none;
    position: absolute;
    z-index: 1;
    margin-bottom: 60px;
}

.popupButtonDescriptionFromTopContainer:hover .popupButtonDescriptionFromTopHidden {
    display: block;
}

/* END OF POPUP BUTTON DESCRIPTION FROM TOP STYLES */



/* UNIVERSAL POPUP SELECT STYLES */

.universalPopupSelectContainer {
    border: none;
    overflow: hidden;
}

.universalPopupSelectVisible {
    /*height: 100%;*/
    position: relative;
}

.universalPopupSelectHidden {
    /*display: none;*/
    position: absolute;

    z-index: 101;
    padding: 10px 15px 10px 15px;
    background-color: var(--white);
    box-shadow: 0px 10px 20px var(--shadow-color);
    border-radius: 20px;
    margin-top: -30px;
    margin-left: -15px;
    white-space: nowrap;
}

/*.universalPopupSelectContainer:hover .universalPopupSelectHidden {
    display: block;
}*/

/* END OF UNIVERSAL POPUP STYLES */

.popupButtonDescription {
    /*height: calc(100%-4.5px-4.5px);*/
    z-index: 1;
    background-color: var(--white);
    padding: 0px 10px 0px 10px;
    border-radius: 20px;
    box-shadow: 0px 3px 16px 0px rgba(0,0,0,0.2);
    white-space: nowrap;
}

.oneLineText {
    display: block;
    white-space: nowrap;
}

.oneLineThreeDotsText {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.oneLineThreeDotsTextDeactivated {
    display: block;
}

.datePicker::-webkit-inner-spin-button {
  display: none;
}
.datePicker::-webkit-calendar-picker-indicator {
  display: none;
}
.datePicker {
    border: 0px solid black;
    text-align: center;
}

/* CUSTOM GLOBAL SCROLLBAR */


::-webkit-scrollbar {
    width: 18px; /* 8 + 5 + 5 = 18 */
    height: 18px;
}

/* Track */
::-webkit-scrollbar-track {
    /* background: var(--lightest-gray); */
    box-shadow: inset 0 0 18px 18px transparent;
    border: solid 0px transparent;
}

::-webkit-scrollbar-thumb {
    /* background: var(--lightest-gray); */
    box-shadow: inset 0 0 18px 18px var(--light-gray);
    /*box-shadow: inset 0 0 18px 18px red;*/
    border: solid 5px transparent;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
    display: none;
}

.myPeopleTile ::-webkit-scrollbar-track { background: var(--white); }
.myPeopleTile ::-webkit-scrollbar-thumb { background: var(--white); }

/* END OF CUSTOM GLOBAL SCROLLBAR*/

.expandButton {
    width: 14px;
    height: 14px;
    margin-top: 0px;
}

.expandButtonExpanded {
    width: 14px;
    height: 14px;
    margin-top: 2px;
    margin-left: -1px;
    transform: rotate(90deg);
}

.expandButtonNew {
    width: 33px;
    height: 33px;
}

.expandButtonExpandedNew {
    width: 33px;
    height: 33px;
    transform: rotate(90deg);
}
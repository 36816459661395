/* START OF myPeopleTile */

.myPeopleTile {
    flex: 1;
    width: calc(100% - 80px);
    background-color: var(--white);
    margin-left: 20px;
    margin-right: 20px;
    height: calc(100% - 60px);
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 10px 20px var(--shadow-color);
}

.myPeopleTable {
    width: 100%;
    position: relative;
    /*background-color: var(--table-header-light-gray);*/
    border-collapse: collapse;
}

.myPeopleTableFirstFixedColumn {
    position: sticky;
    left: 0;
    width: 150px;
    min-width: 150px;
    z-index: 1;
    text-align: center;
    background: var(--white);
    box-shadow: inset -2px -2px 0px var(--border-light-gray);
}
.myPeopleTableSecondFixedColumn {
    position: sticky;
    left: 152px;
    min-width: 50px;
    z-index: 1;
    box-shadow: inset 0px -2px 0px var(--border-light-gray);
}
.myPeopleTableNameHeader {
    padding-left: 35px;
    min-width: 240px;
    top: 0;
    left: 152px;
    position: sticky;
    z-index: 3 !important;
    box-shadow: inset 0px -2px 0px var(--border-light-gray), inset -2px 2px 0px var(--border-light-gray) !important;
}
.myPeopleTableActionHeader {
    text-align: center;
    box-shadow: inset 0px -2px 0px var(--border-light-gray), inset -2px 2px 0px var(--border-light-gray) !important;
    left: 0;
    top: 0;
    width: 150px;
    min-width: 150px;
    position: sticky;
    z-index: 3 !important;
}

.myPeopleTableFirstTwoColumns {
    position: relative;
    background-color: var(--table-header-light-gray); /*var(--border-light-gray);*/
    border-collapse: collapse;
}
.myPeopleTable tr {
    /*border-top: 2px solid var(--border-light-gray);*/
}
.myPeopleTable tr:first-child>td {
    position: sticky;
    top: 0;
    z-index: 2;
}
.myPeopleTableHeader {
    background-color: var(--table-header-light-gray);
    padding-top: 12px;
    padding-bottom: 12px;
    color: var(--darkest-gray);
    font-size: 13px;
    font-family: PoppinsSemiBold;
    box-shadow: inset 0px -2px 0px var(--border-light-gray), inset 0px 2px 0px var(--border-light-gray);
}
.myPeopleTableName {
    padding-left: 35px;
    min-width: 240px;
    border-right: 2px solid var(--border-light-gray);
}
.myPeopleTableNameDiv {
    padding-top: 13px;
    padding-bottom: 15px;
    background-color: var(--white);
}
.myPeopleTableNameDiv span {
    font-family: PoppinsMedium;
    color: var(--darkest-gray);
    font-size: 14px;
}
.myPeopleTableHeaderRole {
    padding-left: 35px;
    min-width: 150px;
}
.myPeopleTableHeaderOther {
    min-width: 150px;
    padding-left: 35px;
}
.myPeopleTableGeneralDiv {
    padding-top: 21px;
    padding-bottom: 23px;
    padding-left: 35px;
    font-family: PoppinsRegular;
    color: var(--darkest-gray);
    font-size: 13px;
    box-shadow: inset 0px -2px 0px var(--border-light-gray);

}
.myPeopleAvatarImage {
    width: 32px;
    height: 32px;
    margin-top: 2px;
    margin-bottom: 2px;
    border-radius: 50%;
    object-fit: cover;
}
.myPeopleMessageImage {
    width: 18px;
}

.myPeopleMessageButton {
    background-color: transparent;
    border-radius: 20px;
    border: 0px solid;
    width: 30px;
    height: 30px;
}
.myPeopleMessageButton:hover {
    background-color: var(--jv-orange);
}
.myPeopleMessageButton:hover > .myPeopleMessageImage {
    filter: brightness(0) invert(1);
}

.myPeopleTable tr:hover > td {
    background-color: var(--lightest-gray);
    cursor: pointer;
}
.myPeopleTable tr:hover > .myPeopleTableNameDiv span {
    color: var(--jv-orange);
}
/*.myPeopleTable tr:hover > .myPeopleTableNameDiv img {
    border: 2px solid var(--jv-orange);
}*/
.myPeopleTable tr > .myPeopleTableNameDiv .myPeopleAvatarImage {
    border: 2px solid var(--white);
}
.myPeopleTable tr:hover > .myPeopleTableNameDiv .myPeopleAvatarImage {
    border: 2px solid var(--jv-orange);
}

/* END OF myPeopleTile */

/* PROFILE TILE */

.profileTile {
    /*height: 203px; /* height of churchInfoTile */
    background-color: var(--white);
    margin-left: 20px;
    margin-right: 10px;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 10px 20px var(--shadow-color);
}

.profileTileInputText {
    height: 23px;
    border: 0px;
}
/* need to make bigger height for select because by default is shows it smaller than normal input */
select.profileTileInputText {
     height: 25px;
}

.avatarImage {
    width: 150px;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;
    border: 10px solid var(--white);
}

.avatarImageInitials {
    background-color: var(--full-black);
    display: flex;
    align-items: center;
    justify-content: center;
}

.avatarImageInitialsPerson {
    background-image: url('../assets/icon_person_profile_bg.png');
    background-size: 100%;
}

.avatarImageInitialsChurch {
    background-image: url('../assets/icon_church_profile_bg.png');
    background-size: 100%;
}

.avatarImageOnHover {
    top: 10px;
    left: 10px;
    width: 150px;
    aspect-ratio: 1/1;
    background-color: var(--jv-orange);
    border-radius: 50%;
    position: absolute;
    opacity: 0.75;
}

.avatarHoverTextContainer {
    width: 150px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 10px;
}

.avatarHoverContainer {
    border: none;
    position: relative;
}

.avatarHoverVisible {
    height: 100%;
    position: relative;
}

.avatarHoverHidden {
    display: none;
    position: absolute;
    top: 0px;
    left: 0px;
    color: red;
    border-radius: 50%;
    z-index: 5;
}
.avatarHoverContainer:hover .avatarHoverHidden {
    display: block;
}

.profileTileContactRadioContainer {
    display: flex;
    flex-flow: row no wrap;
    align-items: center;
    justify-content: center;
}
.profileTileContactRadioButton {
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
}


/* END OF PROFILE TILE */

/* CHURCH INFO TILE */

.churchInfoTile {
    width: 300px;
    background-color: var(--white);
    margin-left: 10px;
    margin-right: 20px;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 10px 20px var(--shadow-color);
}

.churchInfoTileIcon {
    width: 36px;
}

.jvEngagementButton:hover > .jvEngagementValue {
    color: var(--jv-orange);
}

/* END OF CHURCH INFO TILE */


/* MAP TILE */

.mapTile {
    flex: 1;
    background-color: var(--white);
    margin-left: 20px;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 10px 20px var(--shadow-color);
}

/* END OF MAP TILE */

/* UNIVERSAL TILE */
.universalTile {
    background-color: var(--white);
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 10px 20px var(--shadow-color);
}
/* END OF UNIVERSAL TILE */


/* DIAGNOSTIC TILE */

.diagnosticTile {
    width: 300px;
    background-color: var(--white);
    margin-left: 10px;
    margin-right: 20px;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 10px 20px var(--shadow-color);
}

.diagnosticTileIcon {
    width: 47px;
}

.diagnosticLensCircle {
    width: 29px;
    height: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--light-purple-gray);
    border-radius: 20px;
    color: var(--dark-gray);
}

.diagnosticLensOval {
    height: 29px;
    min-width: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--light-purple-gray);
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    color: var(--dark-gray);
}

.diagnosticLensIconExpand {
    width: 8px;
    height: 8px;
}

.diagnosticTileRow {
    padding-top: 11px;
    padding-bottom: 11px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
}

.diagnosticTileRow:hover {
    background-color: var(--lightest-gray);
}

.diagnosticTileRowOpened {
    background-color: var(--lightest-gray);
}

.diagnosticTileSubRow {
    padding-top: 8px;
    padding-bottom: 8px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 34px;
    padding-right: 20px;
}

.diagnosticTileSubRow:hover {
    background-color: var(--lightest-gray);
}

/* change color of level indicator on hover of whole subRow */
.diagnosticTileSubRow:hover .diagnosticLensCircleHoverGreen {
    background-color: var(--crm-green); border: 2px solid var(--crm-green); color: var(--black);
}
.diagnosticTileSubRow:hover .diagnosticLensOvalHoverGreen {
    background-color: var(--crm-green); border: 2px solid var(--crm-green); color: var(--black);
}
.diagnosticTileSubRow:hover .diagnosticLensCircleHoverBlue {
    background-color: var(--blue-button-link); border: 2px solid var(--blue-button-link); color: var(--black);
}
.diagnosticTileSubRow:hover .diagnosticLensOvalHoverBlue {
    background-color: var(--blue-button-link); border: 2px solid var(--blue-button-link); color: var(--black);
}
.diagnosticTileSubRow:hover .diagnosticLensCircleHoverPink {
    background-color: var(--crm-pink); border: 2px solid var(--crm-pink); color: var(--black);
}
.diagnosticTileSubRow:hover .diagnosticLensOvalHoverPink {
    background-color: var(--crm-pink); border: 2px solid var(--crm-pink); color: var(--black);
}
.diagnosticTileSubRow:hover .diagnosticLensCircleHoverYellow {
    background-color: var(--crm-yellow); border: 2px solid var(--crm-yellow); color: var(--black);
}
.diagnosticTileSubRow:hover .diagnosticLensOvalHoverYellow {
    background-color: var(--crm-yellow); border: 2px solid var(--crm-yellow); color: var(--black);
}
/* show the button description also on hover of whole subRow */
.diagnosticTileSubRow:hover .popupButtonDescriptionFromTopHidden {
    display: block;
}

/* END OF DIAGNOSTIC TILE */

/* CLOSED TILE */

.closedTile {
    display: inline-block;
    background-color: var(--white);
    margin-left: 20px;
    margin-right: 10px;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 10px 20px var(--shadow-color);
}

/* END OF CLOSED TILE */



/* RELATIONSHIPS TILE */

.relationshipsTile {
    display: inline-block;
    background-color: var(--white);
    margin-left: 20px;
    margin-right: 10px;
    border-radius: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 10px 20px var(--shadow-color);
}

.relationshipsTable {
    width: 100%;
    position: relative;
    border-collapse: collapse;
}

.relationshipsTableHeader {
    background-color: var(--white);
    padding-top: 12px;
    padding-bottom: 12px;
    color: var(--darkest-gray);
    font-size: 13px;
    font-family: PoppinsSemiBold;
    border: 0px solid var(--border-light-gray);
    border-top: 1px solid var(--border-light-gray);
    border-bottom: 1px solid var(--border-light-gray);
}

.relationshipsTableAction {
    min-width: 60px;
    text-align: center;
}

.relationshipsTableIsStaff {
    text-align: center;
}

.relationshipsTableHeaderOther {
    padding-left: 15px;
    padding-right: 15px;
}

.relationshipsTableGeneralDiv {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-family: PoppinsRegular;
    color: var(--darkest-gray);
    font-size: 13px;
}

.relationshipsTableGeneralDiv span {
    font-family: PoppinsRegular;
    color: var(--darkest-gray);
    font-size: 13px;
}

/* END OF RELATIONSHIPS TILE */


/* CONVERSATIONS TILE */

.conversationsTile {
    display: inline-block;
    background-color: var(--white);
    margin-left: 20px;
    margin-right: 10px;
    border-radius: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 10px 20px var(--shadow-color);
}


/* OBSERVATIONS TILE */

.observationsTile {
    display: inline-block;
    background-color: var(--white);
    margin-left: 20px;
    margin-right: 10px;
    border-radius: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 10px 20px var(--shadow-color);
}

/* ROLES TILE */

.rolesTile {
    display: inline-block;
    background-color: var(--white);
    margin-left: 20px;
    margin-right: 10px;
    border-radius: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 10px 20px var(--shadow-color);
}

.rolesTable {
    width: 100%;
    position: relative;
    border-collapse: collapse;
}


.rolesTable tr:hover {
    background-color: var(--lightest-gray);
}
.relationshipsTable tr:hover {
    background-color: var(--lightest-gray);
}

.rolesTable tr:hover > .rolesInTeamsTableRow {
    color: var(--blue-button-link);
}

.rolesTable tr:hover > .rolesTableRowExpandImage img {
    filter: invert(45%) sepia(23%) saturate(7498%) hue-rotate(2deg) brightness(104%) contrast(103%); /* change color to orange var(--jv-orange) */
}

.rolesTable tr:hover > .rolesTableGeneralDiv {
    color: var(--blue-button-link);
}

.relationshipsTable tr:hover > .relationshipsTableGeneralDiv {
    color: var(--blue-button-link);
}

.rolesTableHeader {
    background-color: var(--white);
    padding-top: 12px;
    padding-bottom: 12px;
    color: var(--darkest-gray);
    font-size: 13px;
    font-family: PoppinsSemiBold;
    border: 0px solid var(--border-light-gray);
    border-top: 1px solid var(--border-light-gray);
    border-bottom: 1px solid var(--border-light-gray);
}

.rolesInTeamsTableRow {
    padding-top: 12px;
    padding-bottom: 12px;
    color: var(--darkest-gray);
    font-size: 13px;
    font-family: PoppinsRegular;
    border: 0px solid var(--border-light-gray);
    border-top: 1px solid var(--border-light-gray);
    border-bottom: 1px solid var(--border-light-gray);
}

.rolesTableAction {
    min-width: 60px;
    text-align: center;
}

.rolesTableIsPrimary {
    text-align: center;
}

.rolesTableHeaderOther {
    padding-left: 15px;
    padding-right: 15px;
}

.rolesTableGeneralDiv {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-family: PoppinsRegular;
    color: var(--darkest-gray);
    font-size: 13px;
}

.rolesTableGeneralDiv span {
    font-family: PoppinsRegular;
    color: var(--darkest-gray);
    font-size: 13px;
}

/* END OF ROLES TILE */

/* START OF MINISTRY STATISTICS TILE */

.statisticsTile {
    display: inline-block;
    background-color: var(--white);
    margin-left: 20px;
    margin-right: 10px;
    border-radius: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 10px 20px var(--shadow-color);
}

.statisticsTable {
    overflow-x: "auto";
    border-collapse: collapse;
    position: relative;
}

.statisticsTable tr:hover {
    background-color: var(--lightest-gray);
}

.statisticsTable tr:first-child:hover {
    background-color: inherit;
}

.statisticsTable tr:hover > .statisticsTableRow {
    color: var(--blue-button-link);
}

.statisticsTable tr:hover > .statisticsTableRowExpandImage img {
    filter: invert(45%) sepia(23%) saturate(7498%) hue-rotate(2deg) brightness(104%) contrast(103%); /* change color to orange var(--jv-orange) */
}

.statisticsTable tr:hover > .statisticsTableGeneralDiv {
    color: var(--blue-button-link);
}

.statisticsTableHeader {
    background-color: var(--white);
    padding-top: 12px;
    padding-bottom: 12px;
    color: var(--darkest-gray);
    font-size: 13px;
    font-family: PoppinsSemiBold;
    border: 0px solid var(--border-light-gray);
    border-top: 1px solid var(--border-light-gray);
    border-bottom: 1px solid var(--border-light-gray);
}

.statisticsTableHeaderOther {
    padding-left: 15px;
    padding-right: 15px;
}

.statisticsTableRow {
    padding-top: 12px;
    padding-bottom: 12px;
    color: var(--darkest-gray);
    font-size: 13px;
    font-family: PoppinsRegular;
    border: 0px solid var(--border-light-gray);
    border-top: 1px solid var(--border-light-gray);
    border-bottom: 1px solid var(--border-light-gray);
}

.statisticsTableGeneralDiv {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-right: 15px;
    font-family: PoppinsRegular;
    color: var(--darkest-gray);
    font-size: 13px;
}

.statisticsTableGeneralDiv span {
    font-family: PoppinsRegular;
    color: var(--darkest-gray);
    font-size: 13px;
}

.statisticsTableFirstFixedColumn {
     position: sticky;
     left: 0;
     z-index: 1;
     width: 10px;
     min-width: 10px;
     text-align: center;
     background-color: inherit;
 }

 .statisticsTableSecondFixedColumn {
     position: sticky;
     left: 40px;
     min-width: 50px;
     z-index: 1;
     background-color: inherit;
     box-shadow: inset -1px 0px 0px var(--border-light-gray);
 }

.statisticsTableFirstFixedColumnHeader {
     position: sticky;
     left: 0;
     z-index: 1;
     width: 10px;
     min-width: 10px;
     text-align: center;
 }

 .statisticsTableSecondFixedColumnHeader{
     position: sticky;
     left: 40px;
     min-width: 50px;
     z-index: 1;
     box-shadow: inset -1px 0px 0px var(--border-light-gray);
 }

/* END OF MINISTRY STATISTICS TILE */


/* START OF MY DISCIPLE-MAKING TILE */

.myDisciplingTable {
    width: 100%;
    flex: 1;
    border-collapse: collapse;
    table-layout: fixed;
}

.myDisciplingTable tr:first-child>td {
    position: sticky;
    top: 0;
    z-index: 2;
}

.myDisciplingTableFirstRowTd {
    text-align: center;
    background: var(--table-header-light-gray);
    padding-top: 10px;
    padding-bottom: 10px;
}

.myDisciplingTableFirstRowTdLine {
    box-shadow: inset 0px -2px 0px var(--border-light-gray), inset -2px 2px 0px var(--border-light-gray) !important;
}

.myDisciplingTableFirstRowTdLast {
    box-shadow: inset 0px -2px 0px var(--border-light-gray), inset 0px 2px 0px var(--border-light-gray) !important;
}

.myDisciplingTableTd {
    align-items: center;
    text-align: center;
}

.myDisciplingTableTdLine {
    box-shadow: inset -2px 0px 0px var(--border-light-gray) !important;
}

.myDisciplingTableLastColumn {
}

/* END OF MY DISCIPLE-MAKING TILE */

/* OTHER */

.circleButtonContainer {
    width: 16px;
    height: 16px;
    background: var(--light-blue);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8.5px;
    border-radius: 20px;
}
.circleButtonContainerOrange {
    background: var(--jv-orange);
}
.circleButtonContainerGray {
    background: var(--dark-gray);
}
.circleButtonContainerBlue {
    background: var(--light-blue);
}
.circleButtonContainerEmpty {
    background: var(--lightest-gray);
}

.circleButtonContainer:hover {
    background: var(--lightest-blue);
}
.circleButtonContainerOrange:hover {
    background: var(--light-jv-orange);
}
.circleButtonContainerGray:hover {
    background: var(--gray-blue);
}
.circleButtonContainerBlue:hover {
    background: var(--lightest-blue);
}

.emptySpace {
    background-color: var(--lightest-gray);
    height: 15px;
    border-radius: 20px;
}

.responsiveTableDiv {
    max-width: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* also those <td> have usually noWrap attribute */
}

/* END OF OTHER */

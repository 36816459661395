.tableContactDetailsMobile {
    width: 100%;
}

.tableContactDetailsMobile td:first-child > input {
    width: 100%;
    width-min: 110px;
    width-max: 150px;
}

.tableContactDetailsMobile td:nth-child(2) {
    width: 90px;
}
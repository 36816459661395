/* Round Button */
.roundButton {
    border: 0px;
    background: var(--light-blue);
    padding-left: 16px;
    padding-right: 20px;
    padding-top: 11.5px;
    padding-bottom: 11.5px;
    border-radius: 20px;
}
.roundButtonOrange {
    background: var(--jv-orange);
}
.roundButtonGray {
    background: var(--dark-gray);
}
.roundButtonLightGray {
    background: var(--purple-gray);
}
.roundButtonGreen {
    background: var(--crm-green);
}
.roundButtonYellow {
    background: var(--crm-yellow);
}
.roundButtonBlue {
    background: var(--blue-button-link);
}
.roundButtonPink {
    background: var(--crm-pink);
}

.roundButton:hover {
    background: var(--lightest-blue);
}
.roundButtonOrange:hover {
    background: var(--light-jv-orange);
}
.roundButtonGray:hover {
    background: var(--darkest-gray);
}
.roundButtonLightGray:hover {
    background: var(--purple-gray);
}
.roundButtonGreen:hover {
    background: var(--light-crm-green);
}
.roundButtonYellow:hover {
    background: var(--light-crm-yellow);
}
.roundButtonBlue:hover {
    background: var(--mid-light-blue);
}
.roundButtonPink:hover {
    background: var(--light-crm-pink);
}

/* Search Box */
.topMenuSearchBox {
    width: 100%;
    background-color: var(--lightest-gray);
    border-radius: 20px;
}

.topMenuSearchBoxInput {
    background-color: transparent;
    background: url("../assets/icon_search.png") no-repeat left;
    background-size: 16px;
    background-position: 12px;
    width: 100%;
    border: 0px solid black;
    border-radius: 20px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 40px;
}
.topMenuSearchBoxInput::placeholder {
    color: var(--dark-gray);
}

.grayTextButton:hover{
    color: var(--jv-orange);
}

.threeDotsButton {
    width: 33px;
    height: 33px;
    text-align: center;
    font-family: PoppinsSemiBold;
    font-size: 20px;
    margin-bottom: -7px;
    border-radius: 20px;
    background-color: transparent;
    border: 1px solid transparent;
    color: var(--darkest-gray);
}

.threeDotsButton:hover {
    background-color: var(--jv-orange);
    border: 1px solid var(--jv-orange);
    color: white;
}

/* Start of EditButtonDropdown */

.editButtonDropdownContainer {
  position: relative;
  color: var(--black);
  font-size: 16px;
  border: none;
}

.editButtonDropdownVisible {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  border-radius: 20px;
}

.editButtonDropdownHidden {
  display: none;
  position: absolute;
  background-color: var(--white);
  margin-top: -47px; /* -42px (30+10) because 30px is size of conversationsDropdown-place and 10px is paddingTop in conversationsDropdown-row */
  margin-left: -54px; /* -54px (70-16) to have left side together with left side of conversationsDropdown-place, 16 is padding-side in conversationsDropdown-row */
  border-radius: 20px;
  width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.editButtonDropdownDeleteHidden {
  display: none;
  position: absolute;
  background-color: var(--white);
  margin-top: -45px; /* -42px (30+10) because 30px is size of conversationsDropdown-place and 10px is paddingTop in conversationsDropdown-row */
  margin-left: -255px; /* -54px (70-16) to have left side together with left side of conversationsDropdown-place, 16 is padding-side in conversationsDropdown-row */
  border-radius: 20px;
  width: 300px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.editButtonDropdownRow  {
  padding: 10px 16px;
}

.editButtonCancel {
    font-family: PoppinsMedium !important;
    font-size: 20px !important;
    color: var(--dark-gray) !important;
}

.editButtonCancel:hover {
    color: var(--black) !important;
}

.editButtonDelete {
    font-family: PoppinsMedium !important;
    font-size: 20px !important;
    color: var(--dark-gray) !important;
}

.editButtonDelete:hover {
    color: var(--delete-logout-red) !important;
}

.editButtonDropdownRow:first-child  {
  border-bottom: 1px solid var(--lightest-gray);
}

.editButtonDropdownRow span {
  color: var(--white);
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
}

.editButtonDropdownRow:hover span {color: var(--jv-orange);}

/* Show the dropdown menu on hover */
.editButtonDropdownContainer:hover .editButtonDropdownHidden {display: block;}
.editButtonDropdownContainer:hover .editButtonDropdownDeleteHidden {display: block;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.editButtonDropdownContainer:hover .editButtonDropdownVisible {background-color: var(--jv-orange);}

/* End of EditButtonDropdown */


/* Start of EditDeleteButton */

.editDeleteButtonImageOpened {
    display: none;
}
.editDeleteButtonImageClosed {
    display: block;
}

.editDeleteButtonVisible {
  position: relative;
  display: inline-block;
  height: 30px;
  text-align: center;
}
.editDeleteButtonContainer:hover .editDeleteButtonImageOpened {display: block;}
.editDeleteButtonContainer:hover .editDeleteButtonImageClosed {display: none;}

/* End of EditDeleteButton */

/* Testing new EditDeleteButton */

.newEditButtonDropdownVisible {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 20px;
    background: url("../assets/editButtonDropdownCircleClosed.png");
    background-size: 100%;
}

.newEditButtonDropdownVisible:hover {
    background: url("../assets/editButtonDropdownCircle.png");
    background-size: 100%;
}

/* End of testing new EditDeleteButton */

/* START OF DISCIPLE BOX */
.discipleBoxContainerActive {
    border: 2px solid var(--crm-green);
    border-radius: 23px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}

.discipleBoxContainerUnactive {
    border: 2px solid var(--purple-gray);
    border-radius: 23px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}

.discipleBoxContainerPrivateActive {
    border: 2px solid var(--lightest-crm-green);
    border-radius: 23px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}

.discipleBoxContainerPrivateUnactive {
    border: 2px solid var(--light-purple-gray);
    border-radius: 23px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
}

.discipleBoxContainerActive:hover { border: 2px solid var(--light-jv-orange); }
.discipleBoxContainerUnactive:hover { border: 2px solid var(--light-jv-orange); }
.discipleBoxContainerPrivateActive:hover { border: 2px solid var(--light-jv-orange); }
.discipleBoxContainerPrivateUnactive:hover { border: 2px solid var(--light-jv-orange); }

.discipleBoxContainerActive:hover > .discipleBoxHeaderActive {
    background: var(--light-jv-orange);
    border-color: var(--light-jv-orange);
}
.discipleBoxContainerUnactive:hover > .discipleBoxHeaderUnactive {
    background: var(--light-jv-orange);
    border-color: var(--light-jv-orange);
}
.discipleBoxContainerPrivateActive:hover > .discipleBoxHeaderPrivateActive {
    background: var(--light-jv-orange);
    border-color: var(--light-jv-orange);
}
.discipleBoxContainerPrivateUnactive:hover > .discipleBoxHeaderPrivateUnactive {
    background: var(--light-jv-orange);
    border-color: var(--light-jv-orange);
}

.discipleBoxContainerActive:hover > .discipleBoxShowOnHover { display: block; }
.discipleBoxContainerUnactive:hover > .discipleBoxShowOnHover { display: block; }
.discipleBoxContainerPrivateActive:hover > .discipleBoxShowOnHover { display: block; }
.discipleBoxContainerPrivateUnactive:hover > .discipleBoxShowOnHover { display: block; }

.discipleBoxContainerActive:hover > .discipleBoxHideOnHover { display: none; }
.discipleBoxContainerUnactive:hover > .discipleBoxHideOnHover { display: none; }
.discipleBoxContainerPrivateActive:hover > .discipleBoxHideOnHover { display: none; }
.discipleBoxContainerPrivateUnactive:hover > .discipleBoxHideOnHover { display: none; }

.discipleBoxShowOnHover {
    display: none;
}

.discipleBoxHeaderActive {
    background: var(--crm-green);
    border: 3px solid var(--crm-green);
    border-top: 1px solid var(--crm-green);
    margin-left: -2px;
    margin-right: -2px;
    border-radius: 20px 20px 23px 23px;
}
.discipleBoxHeaderUnactive {
    background: var(--purple-gray);
    border: 3px solid var(--purple-gray);
    border-top: 1px solid var(--purple-gray);
    margin-left: -2px;
    margin-right: -2px;
    border-radius: 20px 20px 23px 23px;
}
.discipleBoxHeaderPrivateActive {
    background: var(--lightest-crm-green);
    border: 3px solid var(--lightest-crm-green);
    border-top: 1px solid var(--lightest-crm-green);
    margin-left: -2px;
    margin-right: -2px;
    border-radius: 20px 20px 23px 23px;
}
.discipleBoxHeaderPrivateUnactive {
    background: var(--light-purple-gray);
    border: 3px solid var(--light-purple-gray);
    border-top: 1px solid var(--light-purple-gray);
    margin-left: -2px;
    margin-right: -2px;
    border-radius: 20px 20px 23px 23px;
}
/* END OF DISCIPLE BOX */

/* STYLES FOR DASHBOARD GRAPHS */
.canvas-container {
    width: 99%;
}




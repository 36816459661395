/* GENERAL */

.modalCloseButton {
    width: 20px;
    height: 20px;
    background: var(--dark-gray);
    padding: 8px;
    border-radius: 20px;
}
.modalCloseButton:hover {
    background: var(--darkest-gray);
}

.modalInputAddNote {
    flex: 1;
    box-sizing: "border-box";
    margin-top: 10px;
    padding: 10px;
    background-color: var(--lightest-gray);
    border: 0px;
    font-family: PoppinsRegular;
    font-size: 13px;
    color: var(--black);
    resize: none;
}
.modalInputAddNoteBottom {
    height: 250px;
    max-height: 250px;
    overflow-y: scroll;
}
.modalInputAddNoteSide {
    flex: 1 0 50px;
    overflow-y: scroll;
}

.modalInputAddNote::placeholder {
    font-family: PoppinsItalic;
    font-size: 13px;
    color: var(--dark-gray);
}

.modalHistoryDiv {
    height: 100%;
    background-color: var(--lightest-gray);
    border: 0px;
    overflow-wrap: break-word;
}

.modalHistoryDate {
    line-height: 3.8;
    padding-right: 5px;
    white-space: nowrap;
}

.modalExpandButtonIconContainer {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    background: var(--white);
    border-radius: 30px;
}

.modalExpandButtonIcon {
    width: 14px;
    height: 8.8px;
}

.modalExpandButtonGreen {
    filter: invert(67%) sepia(96%) saturate(357%) hue-rotate(100deg) brightness(86%) contrast(97%);
}

.modalExpandButtonBlue {
    filter: invert(58%) sepia(54%) saturate(6945%) hue-rotate(216deg) brightness(93%) contrast(93%);
}

.modalExpandButtonYellow {
    filter: invert(67%) sepia(63%) saturate(845%) hue-rotate(10deg) brightness(111%) contrast(98%);
}
.modalExpandButtonPink {
    filter: invert(54%) sepia(37%) saturate(5575%) hue-rotate(297deg) brightness(99%) contrast(98%);
}

.modalExpandButtonTextGreen { color: var(--crm-green); }
.modalExpandButtonTextBlue { color: var(--blue-button-link); }
.modalExpandButtonTextGray { color: var(--dark-gray); }
.modalExpandButtonTextPink { color: var(--crm-pink); }

.modalExpandButton:hover > .modalExpandButtonTextGreen { color: var(--black); }
.modalExpandButton:hover > .modalExpandButtonTextBlue { color: var(--black); }
.modalExpandButton:hover > .modalExpandButtonTextGray { color: var(--black); }
.modalExpandButton:hover > .modalExpandButtonTextPink { color: var(--black); }
.modalExpandButton:hover > .modalExpandButtonIconBgGreen { background: var(--crm-green); }
.modalExpandButton:hover > .modalExpandButtonIconBgBlue { background: var(--blue-button-link); }
.modalExpandButton:hover > .modalExpandButtonIconBgPink { background: var(--crm-pink); }
.modalExpandButton:hover > .modalExpandButtonIconBgYellow { background: var(--crm-button-yellow); }
.modalExpandButton:hover .modalExpandButtonGreen { filter: brightness(0) saturate(100%); }
.modalExpandButton:hover .modalExpandButtonBlue { filter: brightness(0) saturate(100%); }
.modalExpandButton:hover .modalExpandButtonYellow { filter: brightness(0) saturate(100%); }
.modalExpandButton:hover .modalExpandButtonPink { filter: brightness(0) saturate(100%); }


.modalExpandButton:hover > .modalExpandButtonTextBlue { color: var(--black); }


.fakeEndOfModalWindow {
    height: 20px;
    background-color: var(--white);
    margin-left: -20px;
    margin-right: -20px;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 15px 20px -8px rgba(0,0,0,0.52);
}

.modalDatePicker {
    border: 0px solid var(--black);
    box-shadow: none;
    height: 25px;
}
.modalDatePicker:focus-visible { /* Chrome */
  box-shadow: 0 0 0 0px var(--jv-orange);
  outline: 0;
}

.modalHistoryContainer {
    padding-left: 300px;
    margin-left: -300px;
    height: 250px;
    margin-top: 10px;
    overflow-y: scroll;
}
.modalHistoryContainer::-webkit-scrollbar-track {
    background: var(--lightest-gray);
}
.modalHistoryContainer::-webkit-scrollbar-thumb {
    background: var(--lightest-gray);
}

/* FIVE CHALLENGES MODAL WINDOW */

/* GRO TOOL MODAL WINDOW*/


.groToolProgressBar {
    background: var(--light-gray); /* for Firefox */
    border-radius: 20px; /* for Firefox */
    height: 6px;
    width: 100%;
    border: 0px solid var(--black); /* for Firefox */
}

.groToolProgressBar::-webkit-progress-bar {
    background: var(--light-gray);
    border-radius: 20px;
}
.groToolProgressBar::-moz-progress-bar {
    background: var(--crm-green);
    border-radius: 20px;
}

.groToolProgressBar::-webkit-progress-value {
    background: var(--crm-green);
    border-radius: 20px;
}

/* MINISTRY PHASES MODAL WINDOW */


.ministryPhasesProgressBar {
    background: var(--light-gray); /* for Firefox */
    border-radius: 20px; /* for Firefox */
    height: 10px;
    border: 0px solid var(--black); /* for Firefox */
}

.ministryPhasesProgressBar::-webkit-progress-bar {
    background: var(--light-gray);
    border-radius: 20px;
}
.ministryPhasesProgressBar::-moz-progress-bar {
    background: var(--crm-pink);
    border-radius: 20px;
}

.ministryPhasesProgressBar::-webkit-progress-value {
    background: var(--crm-pink);
    border-radius: 20px;
}

/* INSTRUCTIONS SECTION */

.instructionsSectionOpenedModal {
    background: var(--crm-yellow);
    border: 2px solid var(--crm-yellow);
    border-radius: 20px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
}

.instructionSectionClosedModal {
    border: 2px solid var(--light-purple-gray);
    border-radius: 20px;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;
    padding-right: 10px;
}

/* GRO TOOL MODAL WINDOW */


/* ALERT MODAL MESSAGE */

.alertMessageButtonOnHoverTextToBlack:hover span {
    color: var(--black);
}

.alertMessageButtonOnHoverTextToRed:hover span {
    color: var(--delete-logout-red);
}

/* END OF ALERT MODAL MESSAGE */


.searchBoxPlaceholder + label {
  position: absolute;
}
.searchBoxPlaceholder:invalid + label {
  display: inline-block;
}

.searchBoxPlaceholder:valid + label {
  display: none;
}





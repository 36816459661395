.profileTileMobile {
    /*height: 203px; /* height of churchInfoTile */
    background-color: var(--white);
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 10px 20px var(--shadow-color);
}

/* DIAGNOSTIC TILE */

.diagnosticTileMobile {
    background-color: var(--white);
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 10px 20px var(--shadow-color);
}

/* START OF myPeopleTile */

.myPeopleTileMobile {
    flex: 1;
    width: 100%;
    background-color: var(--white);
    height: 100%;
}

.myPeopleTableMobile {
    width: 100%;
    position: relative;
    /*background-color: var(--table-header-light-gray);*/
    border-collapse: collapse;
}

.myPeopleTableMobile tr:first-child>td {
    position: sticky;
    top: 0;
    z-index: 2;
}

.myPeopleTableMobile tr:hover > td {
    background-color: var(--lightest-gray);
    cursor: pointer;
}
.myPeopleTableMobile tr:hover > .myPeopleTableNameDiv span {
    color: var(--jv-orange);
}
.myPeopleTableMobile tr:hover > .myPeopleTableNameDiv img {
    border: 2px solid var(--jv-orange);
}

.myPeopleTableActionHeaderMobile {
    text-align: center;
    box-shadow: inset 0px -2px 0px var(--border-light-gray), inset -2px 2px 0px var(--border-light-gray) !important;
    left: 0;
    top: 0;
    width: 60px;
    min-width: 60px;
    position: sticky;
    z-index: 3 !important;
}

.myPeopleTableFirstFixedColumnMobile {
    position: sticky;
    left: 0;
    width: 60px;
    min-width: 60px;
    z-index: 1;
    text-align: center;
    background: var(--white);
    box-shadow: inset -2px -2px 0px var(--border-light-gray);
}

/* END OF myPeopleTile */

.diagnosticTileRowMobile {
    padding-top: 11px;
    padding-bottom: 11px;
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
}

.avatarImageMobile {
    width: 100px;
    aspect-ratio: 1/1;
    object-fit: cover;
    border-radius: 50%;
    border: 10px solid var(--white);
}

.avatarImageOnHoverMobile {
    top: 10px;
    left: 10px;
    width: 100px;
    aspect-ratio: 1/1;
    background-color: var(--jv-orange);
    border-radius: 50%;
    position: absolute;
    opacity: 0.75;
}

.avatarHoverTextContainerMobile {
    width: 100px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    left: 10px;
}

.churchInfoTileMobile {
    /*width: 300px;*/
    background-color: var(--white);
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 10px 20px var(--shadow-color);
}
/* MAIN LEFT MENU */
.mainLeftMenuMobile {
    background-color: var(--full-black);
    color: var(--white);
    width: 220px; /* 240px including the padding */
    zIndex: 1;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 27px;
}
.mainLeftMenuClosedMobile {
    background-color: var(--full-black);
    color: var(--white);
    width: 60px; /* 90px including the padding */
    zIndex: 1;
    padding-top: 10px;
}



.openLeftMenuButtonMobile {
    width: 35px;
    height: 35px;
    background-color: var(--full-black);
    background-image: url('../assets/closeLeftMenuButton.png');
    background-size: 100%;
    align-items: center;
    justify-content: center;
    bottom: 17px;
    transform: rotate(180deg);
    cursor: pointer;
}

.closeLeftMenuButtonMobile {
    width: 35px;
    height: 35px;
    background-color: var(--full-black);
    background-image: url('../assets/closeLeftMenuButton.png');
    background-size: 100%;
    align-items: center;
    justify-content: center;
    bottom: 17px;
    cursor: pointer;
}

/* TopMenuUserButtonDropdown */

.topMenuUserButtonDropdownContainerMobile {
    border: none;
    height: 100%;
}

.topMenuUserButtonDropdownVisibleMobile {
    height: 100%;
}

.topMenuUserButtonDropdownHiddenMobile {
    display: none;
    position: absolute;
    background-color: var(--white);
    margin-top: 0px;
    margin-left: 0px;
    border-radius: 0px 20px 20px 20px;
    width: 400px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 5;
}

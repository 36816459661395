/* MY CUSTOM SLIDER */
.myCustomSlider {
    -webkit-appearance: none;
}

.myCustomSliderVertical {
    position: absolute;
    -webkit-appearance: none;
    /* writing-mode: vertical-rl; */
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform-origin: right;
}

.myCustomSlider::-webkit-slider-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 4px solid var(--white);
    margin-top: -16px;
    transition: background .3s ease-in-out;
}
.myCustomSlider::-moz-range-thumb {
    -webkit-appearance: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: 4px solid var(--white);
    margin-top: -16px;
    transition: background .3s ease-in-out;
}

.myCustomSlider::-webkit-slider-runnable-track {
    height: 8px;
    cursor: pointer;
    border-radius: 10px;
}
.myCustomSlider::-moz-range-track {
    -webkit-appearance: none;
    width: 100%;
    height: 8px;
    cursor: pointer;
    border-radius: 10px;
}

.myCustomSliderGreen::-webkit-slider-thumb { background: var(--crm-green); }
.myCustomSliderGreen::-webkit-slider-runnable-track { background: var(--crm-green); }
.myCustomSliderGreen::-moz-range-thumb { background: var(--crm-green); }
.myCustomSliderGreen::-moz-range-track { background: var(--crm-green); }

.myCustomSliderBlue::-webkit-slider-thumb { background: var(--blue-button-link); }
.myCustomSliderBlue::-webkit-slider-runnable-track { background: var(--blue-button-link); }
.myCustomSliderBlue::-moz-range-thumb { background: var(--blue-button-link); }
.myCustomSliderBlue::-moz-range-track { background: var(--blue-button-link); }

.myCustomSliderYellow::-webkit-slider-thumb { background: var(--crm-yellow); }
.myCustomSliderYellow::-webkit-slider-runnable-track { background: var(--crm-yellow); }
.myCustomSliderYellow::-moz-range-thumb { background: var(--crm-yellow); }
.myCustomSliderYellow::-moz-range-track { background: var(--crm-yellow); }

.myCustomSliderPink::-webkit-slider-thumb { background: var(--crm-pink); }
.myCustomSliderPink::-webkit-slider-runnable-track { background: var(--crm-pink); }
.myCustomSliderPink::-moz-range-thumb { background: var(--crm-pink); }
.myCustomSliderPink::-moz-range-track { background: var(--crm-pink); }

/*
input[type=range]::-ms-thumb {
input[type=range]::-moz-range-thumb {

input[type=range]::-moz-range-track {
input[type=range]::-ms-track {
input[type=range]::-ms-fill-lower {
*/
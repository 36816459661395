/* START OF PEOPLE_LIST PAGE */

.myPeopleTableListingBar {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}
.buttonPagingUp {
    width: 10px;
    height: 10px;
    background-image: url('../assets/icon_paging_up.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 70% 70%;
}
.buttonPagingUp:hover {
    background-image: url('../assets/icon_paging_up_orange.png');
}
.buttonPagingDown {
    width: 10px;
    height: 10px;
    background-image: url('../assets/icon_paging_down.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 70% 70%;
}
.buttonPagingDown:hover {
    background-image: url('../assets/icon_paging_down_orange.png');
}

/* END OF PEOPLE_LIST PAGE */

/* START OF CHURCHES_LIST PAGE */

.myChurchesTableListingBar {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}

/* END OF CHURCHES_LIST PAGE */

/* START OF MY_CONVERSATIONS PAGE */
.myConversationsTableListingBar {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
}
/* END OF MY_CONVERSATIONS PAGE */

/* START OF LOGIN PAGE */
.loginPage {
    width: 100%;
    height: var(--doc-height);
    background-color: var(--black);
    background-image: url('../assets/background_sign_in.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginPageEmailElement {
    background: var(--lightest-gray);
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 20px;
}

.loginPagePasswordElement {
    background: var(--lightest-gray);
    padding-left: 18px;
    border-radius: 20px;
}

.loginPageEmailInput {
    width: 100%;
    background: var(--transparent);
    border: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.loginPagePasswordInput {
    width: 100%;
    background: var(--transparent);
    border: 0px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.loginPageLoginButton {
    background: var(--light-blue);
    white-space: nowrap;
    border: 0px;
    border-radius: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}
.loginPageLoginButton:hover {
    background: var(--lightest-blue);
}

/* END OF LOGIN PAGE */


/* START OF INSTRUCTIONS PAGE */

.instructions-anim-center-to-left-enter {
    -webkit-animation: centerToLeft 0.5s ease-in-out;
    -moz-animation: centerToLeft 0.5s ease-in-out;
    animation: centerToLeft 0.5s ease-in-out;
}

.instructions-anim-center-to-right-enter {
    -webkit-animation: centerToRight 0.5s ease-in-out;
    -moz-animation: centerToRight 0.5s ease-in-out;
    animation: centerToRight 0.5s ease-in-out;
}

.instructions-anim-right-to-center-enter {
    -webkit-animation: rightToCenter 0.5s ease-in-out;
    -moz-animation: rightToCenter 0.5s ease-in-out;
    animation: rightToCenter 0.5s ease-in-out;
}

.instructions-anim-left-to-center-enter {
    -webkit-animation: leftToCenter 0.5s ease-in-out;
    -moz-animation: leftToCenter 0.5s ease-in-out;
    animation: leftToCenter 0.5s ease-in-out;
}

@keyframes centerToLeft {
    0% {
        opacity: 1;
        transform: translate(0px,0);
    }
    100% {
        opacity: 0;
        transform: translate(-100px,0);
    }
}

@keyframes centerToRight {
    0% {
        opacity: 1;
        transform: translate(0px,0);
    }
    100% {
        opacity: 0;
        transform: translate(100px,0);
    }
}

@keyframes rightToCenter {
    0% {
        opacity: 0;
        transform: translate(100px,0);
    }
    100% {
        opacity: 1;
        transform: translate(0px,0);
    }
}

@keyframes leftToCenter {
    0% {
        opacity: 0;
        transform: translate(-100px,0);
    }
    100% {
        opacity: 1;
        transform: translate(0px,0);
    }
}
/* END OF INSTRUCTIONS PAGE */

.instructionDescription {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 12;
    -webkit-box-orient: vertical;
}

/* START OF TESTING PAGE */

.customMultiSelect {
    width: 200px;
    background-color: red;
    border: 0px solid black;
}


/* END OF TESTING PAGE */

/* MAIN LEFT MENU */
.mainLeftMenu {
    background-color: var(--full-black);
    color: var(--white);
    width: 220px; /* 240px including the padding */
    zIndex: 1;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 27px;
}
.mainLeftMenuClosed {
    background-color: var(--full-black);
    color: var(--white);
    width: 70px; /* 90px including the padding */
    zIndex: 1;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
}
.menuLeftSection {
    margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
}
.menuLeftSectionTitle {
    color: var(--lightest-gray);
    font-size: 13px;
    font-family: PoppinsSemiBold;
}
.menuLeftLinkImage {
    height: 23px;
}
.menuLeftLinkText {
    font-family: PoppinsRegular;
    color: var(--light-purple-gray);
    text-decoration: none;
    font-size: 14px;
    padding-left: 8px;
}
.menuLeftLinkTextActive {
    color: var(--white);
}
.leftMenuButton {
    background-color: var(--full-black);
    border: 0px solid white;
    border-radius: 25px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin-top: 10px;
}
.leftMenuButton:hover > .menuLeftLinkText {
    color: var(--white);
}
.leftMenuButton:hover > .menuLeftLinkImage {
    filter: brightness(0) invert(1);
}
.leftMenuButtonClosed {
    background-color: var(--full-black);
    border: 0px solid white;
    border-radius: 25px;
    width: 40px;
    height: 40px;
    margin-top: 8px;
    align-items: center;
}
.leftMenuButtonClosed:hover > .menuLeftLinkImage {
    filter: brightness(0) invert(1);
}
.leftButtonActive {
    background-color: var(--jv-orange);
}
.hideVerticalScrollBar {
    scrollbar-width: none; /* hidding scrollbar on Firefox */
}
.hideVerticalScrollBar::-webkit-scrollbar {
    width: 0px;
}
.closeLeftMenuButton {
    width: 35px;
    height: 35px;
    background-color: var(--full-black);
    background-image: url('../assets/closeLeftMenuButton.png');
    background-size: 100%;
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 17px;
    cursor: pointer;
}
.closeLeftMenuButton:hover {
    background-image: url('../assets/closeLeftMenuButtonLight.png');
}
.openLeftMenuButton {
    width: 35px;
    height: 35px;
    background-color: var(--full-black);
    background-image: url('../assets/closeLeftMenuButton.png');
    background-size: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 17px;
    transform: rotate(180deg);
    cursor: pointer;
}
.openLeftMenuButton:hover {
    background-image: url('../assets/closeLeftMenuButtonLight.png');
}

/* MAIN TOP MENU */

.mainTopMenu {
    /*width: calc(100vw - 80px); /* 80px a 110px */
    height: 80px;
    background-color: var(--white);
    box-shadow: 0px 10px 20px var(--shadow-color);
    z-index: 12;
    padding-left: 20px;
}

.advancedTopMenu {
    background-color: var(--white);
    border-top: 2px solid var(--lightest-gray);
    box-shadow: 0px 10px 20px var(--shadow-color);
    padding: 24px;
}

.topMenuUserAvatar {
    width: 39px;
    height: 39px;
    border-radius: 30px;
    border: 2px solid transparent;
}

.topMenuUserButton {
    height: 100%;
}

.topMenuUserButton:hover > span {
    color: var(--jv-orange);
}
.topMenuUserButton:hover > img {
    border: 2px solid var(--jv-orange);
}


.topMenuSelectBox {
    display: inline-block;
    line-height: 1.5em;
    margin: 0;
    color: var(--black);
    padding-left: 5px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: var(--white);
    background-image:
        url("../assets/icon_expand_down_white.png"),
        linear-gradient(to right, var(--light-purple-gray), var(--light-purple-gray));
    background-size:
        9px,
        24px;
    background-position:
        calc(100% - 8px) 9px,
        100% 10%;
    background-repeat: no-repeat;
}




/* TopMenuUserButtonDropdown */

.topMenuUserButtonDropdownContainer {
    border: none;
    height: 100%;
}

.topMenuUserButtonDropdownVisible {
    height: 100%;
}

.topMenuUserButtonDropdownHidden {
    display: none;
    position: absolute;
    background-color: var(--white);
    margin-top: 0px;
    margin-left: 0px;
    border-radius: 0px 20px 20px 20px;
    width: 400px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 5;
}

.topMenuUserButtonDropdownRow  {
    padding: 15px 24.5px;
    border-top: 1px solid var(--border-light-gray);
}

.topMenuUserButtonDropdownRow:first-child  {
    border-top: 2px solid var(--light-gray);
}

.topMenuUserButtonDropdownRow span {
    cursor: pointer;
}

.topMenuUserButtonDropdownRow span:hover {
    color: var(--jv-orange);
}

.topMenuUserButtonDropdownContainer:hover .topMenuUserButtonDropdownHidden {
    display: block;
}
.topMenuUserButtonDropdownContainer:hover .topMenuUserButtonDropdownVisible {
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

/* END OF TopMenuUserButtonDropdown */

/* ADD NEW BUTTON DROPDOWN */

.addNewButtonDropdownContainer {
    border: none;
    position: relative;
}

.addNewButtonDropdownVisible {
    /*height: 100%;*/
    position: relative;
    z-index: 11;
}

.addNewButtonDropdownHidden {
    display: none;
    position: absolute;
    z-index: 10;
    top: 50%;
    width: 100%;
    background-color: var(--white);
    padding-top: 20px;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
}

.addNewButtonDropdownContainer:hover .addNewButtonDropdownHidden {
    display: block;
}

/* AddNewButtonPeople part */

.addNewButtonPeopleContainer {
    border: none;
    position: relative;
}

.addNewButtonPeopleList {
    position: relative;
    z-index: 100;
}

/*.addNewButtonPeopleContainer:hover .addNewButtonPeopleText { color: var(--jv-orange); }*/
/*.addNewButtonPeopleContainer:hover .addNewButtonPeopleList { display: block; }*/
/*.addNewButtonPeopleContainer:hover .addNewButtonPeopleExpandIcon { transform: rotate(90deg); }*/

/* AddNewButtonChurches part */

.addNewButtonChurchesContainer {
    border: none;
    position: relative;
}

.addNewButtonChurchesList {
    position: relative;
    z-index: 100;
}

/*.addNewButtonChurchesContainer:hover .addNewButtonChurchesText { color: var(--jv-orange); }*/
/*.addNewButtonChurchesContainer:hover .addNewButtonChurchesList { display: block; }*/
/*.addNewButtonChurchesContainer:hover .addNewButtonChurchesExpandIcon { transform: rotate(90deg); }*/

/* END OF ADD NEW BUTTON DROPDOWN */

















